// Mobile hamburger menu
$('.mob-hamburger').click(function (e) {
	e.preventDefault();

	$('body').toggleClass('no-scroll');
	$(this).toggleClass('mod-active');
	$('.nav--mobile').toggleClass('mod-active');
});

// Function for popout window
function windowPopup(url, width, height) {
	// Calculate the position for the popup so it’s centered on the screen.
	const left = (screen.width / 2) - (width / 2);
	const top = (screen.height / 2) - (height / 2);

	// opens a window with the given url & set position from above
	window.open(url, "", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left);
}

// Ensure the function exists on the page before we call it.
if (!!window['gdprSubscribeModal']) {
	// The form of which to attach the modal to.
	const formSelector = '#contact-form';
	// The url to link to for the privacy policy.
	const privacyPolicyURL = 'privacy-policy.html';
	// Color of the links in the modal
	const linkColour = '#000';
	// Color of the buttons in the modal.
	const buttonTextColour = '#000';

	// Call this per form.
	gdprSubscribeModal(formSelector, privacyPolicyURL, linkColour, buttonTextColour);
}

$(document).ready(function () {
	// Service accordion toggle
	$('.item-service').on('click', function() {
		$(this).toggleClass('active');
	})

	// Faq accordion toggle
	$('.item-faq').on('click', function() {
		$(this).toggleClass('active');
	})

	// How To Pay accordion toggle
	$('.item-way-to-pay--mobile').on('click', function() {
		$(this).toggleClass('active');
	})

	$('.sub-alert').on('click', function () {
		$(this).addClass('mod-hide');
	})
});

if ($(window).width() <= 768) {
	$('.section-main .list-values').slick({
		arrows: true,
		autoplay: false,
		dots: true,
		cssEase: 'linear',
		infinite: true,
		initialSlide: 0,
		slidesToScroll: 2,
		slidesToShow: 2,
		appendDots: $(".slide-m-dots"),
		prevArrow: $(".slide-m-prev"),
		nextArrow: $(".slide-m-next"),
	});

	$('.list-accreditations').slick({
		arrows: true,
		autoplay: false,
		dots: true,
		cssEase: 'linear',
		infinite: true,
		initialSlide: 0,
		slidesToScroll: 2,
		slidesToShow: 2,
		appendDots: $(".slide-m-dots"),
		prevArrow: $(".slide-m-prev"),
		nextArrow: $(".slide-m-next"),
	});
}

	// hide cookie policy
	document.querySelector('.section-cookies .btn-accept').addEventListener('click', (e) => {
		e.preventDefault();

		// If no validation errors
		$.ajax({
			method: "POST",
			url: "api/cookies/",
			data: {
				'cookies-accepted': 1,
			}
		}).done(function () {
			$('.section-cookies').addClass('mod-hide').removeClass('show');
		});
	});

	document.querySelector('.section-cookies .btn-decline').addEventListener('click', (e) => {
		e.preventDefault();

		$.ajax({
			method: "POST",
			url: "api/cookies/",
			data: {
				'cookies-accepted': 0,
			}
		}).done(function () {
			$('.section-cookies').addClass('mod-hide').removeClass('show');
		});
	});